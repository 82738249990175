<template>
  <div class="flex justify-between">
    <button
      class="bg-lightBlue py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase"
      @click="openScanner"
    >
      <span style="font-size: 10px"> Scan Product </span>
    </button>
    <button
      class="bg-lightBlue py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase"
      @click="openTicketModal"
    >
      <span style="font-size: 10px">
        {{
          (item.items && item.items.length) === 0
            ? "Open Ticket"
            : "Save Ticket"
        }}
      </span>
    </button>
    <button
      class="bg-lightBlue py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase"
      @click="quickSaleModalCtrl = true"
    >
      <span style="font-size: 10px"> Quick Sale </span>
    </button>

    <trac-modal
      v-if="showScannerModalCtrl"
      @close="showScannerModalCtrl = false"
    >
      <h2 class="">Scan QR Code</h2>
      <StreamBarcodeReader
        class="mt-4"
        v-show="QRReaderActive"
        @decode="onDecode"
        @loaded="onLoaded"
      ></StreamBarcodeReader>
      <div
        class="flex h-32 justify-center items-center"
        v-show="scannerLoading"
      >
        <p class="">Please wait...</p>
      </div>
    </trac-modal>

    <trac-modal @close="quickSaleModalCtrl = false" v-if="quickSaleModalCtrl">
      <QuickSale
        class="mt-4"
        @charge-amount="
          (e) => {
            $emit('charge-amount', e);
            quickSaleModalCtrl = false;
          }
        "
      ></QuickSale>
    </trac-modal>

    <trac-loading v-if="addingTicket" />
    <trac-modal
      v-else-if="ticketModalCtrl && item.items.length > 0"
      @close="ticketModalCtrl = false"
    >
      <NewTicket :saveTicketName="saveTicketName" @create-ticket="saveTicket" />
    </trac-modal>

    <trac-modal
      v-if="ticketModalCtrl && !item.items.length"
      @close="ticketModalCtrl = false"
    >
      <OpenTicket :tickets="tickets" @select-ticket="openTicket" />
    </trac-modal>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import QuickSale from "../../pos/quickSale";
import NewTicket from "../../pos/NewTicket.vue";
import OpenTicket from "../../pos/OpenTicket.vue";
import { GET_LOCAL_DB_DATA } from "../../../browser-db-config/localStorage";

export default {
  name: "POSActions",
  emits: ["save-ticket", "charge-amount"],
  props: {
    item: {
      type: Object,
      defualt: () => {},
    },
  },
  components: {
    StreamBarcodeReader,
    QuickSale,
    NewTicket,
    OpenTicket,
  },
  data() {
    return {
      tickets: [],
      addingTicket: false,
      saveTicketName: "",
      ticketName: "",
      quickSaleModalCtrl: false,
      showScannerModalCtrl: false,
      QRReaderActive: false,
      ticketModalCtrl: false,
      isLoading: false,
      store: {},
    };
  },
  created() {
    this.store = GET_LOCAL_DB_DATA("pos-sales-store");
  },
  methods: {
    openScanner() {
      this.scannerLoading = true;
      this.showScannerModalCtrl = true;
      this.QRReaderActive = true;
    },
    async openTicketModal() {
      if (this.item?.items?.length === 0) {
        // this.isLoading = true;
        // await this.fetchTickets();
        // this.isLoading = false;
      } else {
        // this.$emit("save-ticket");
      }
      this.ticketModalCtrl = true;
    },
    async fetchTickets() {
      this.isLoading = true;
      console.log(this.store);
      let u = await this.$store.dispatch("FETCH_UNPAID_SALES", this.store);
      const res = this.$store.getters["GET_SALE_RES"];
      // (res, "kerereret");
      this.$store.commit("SET_UP_SALE_RES", null);

      if (res && res.status) {
        const tickets = res.data || [];
        this.tickets = tickets.filter((ticket) => {
          return ticket.ticket_name !== "";
        });
      } else {
        eventBus.$emit("trac-alert", {
          message: "There are currently no tickets",
        });
      }
      this.isLoading = false;
    },
    onLoaded() {
      this.scannerLoading = false;
      this.QRReaderActive = true;
    },
    onDecode(result) {
      //   const scannedProduct = (this.sale.products || []).find(
      //     (product) => product.barcode === result
      //   );
      //   if (scannedProduct) {
      //     this.selectProduct(scannedProduct);
      //     this.showScannerModalCtrl = false;
      //   } else {
      //     eventBus.$emit("trac-alert", {
      //       message: "Product doesn't exist.",
      //     });
      //   }
    },
    openTicket() {},
    saveTicket(ticketName) {
      const vm = this;
      vm.addingTicket = true;
      vm.$emit("save-ticket", {
        ticketName,
        onSuccess() {
          vm.addingTicket = false;
          vm.ticketModalCtrl = false;
          vm.saveTicketName = "";
        },
        onError() {
          vm.addingTicket = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
