var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-5"},[_c('adv-dropdown',{staticClass:"p-1.5",attrs:{"module":'customers',"string-fields":['first_name', 'last_name'],"add-record-page":'AddCustomer'},on:{"on-selection":_vm.selectCustomer,"add-record-path":_vm.gotoPage}})],1),(_vm.item.items.length)?_c('div',{staticClass:"border border-lightGray-100 rounded-md mb-4"},[_c('div',{staticClass:"px-6 py-8 flex flex-col gap-6 overflow-y-scroll",staticStyle:{"max-height":"300px"}},_vm._l((_vm.itemsWithDiscount),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"flex justify-between"},[_c('div',[_c('h6',{staticClass:"text-sm"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"text-xs text-primaryGray"},[_vm._v(" "+_vm._s(item.quantity)+" X "+_vm._s(_vm._f("formatPrice")(item.salePrice))+" Each ")])]),_c('div',{staticClass:"flex gap-2"},[_c('div',[(item.discount)?_c('h6',{staticClass:"text-sm font-bold"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.getDiscount(item)))+" ")]):_vm._e(),_c('h6',{staticClass:"font-bold",class:{
                  'line-through text-primaryGray text-xs': item.discount,
                }},[_vm._v(" "+_vm._s(_vm._f("formatPrice")((item.salePrice * item.quantity)))+" ")])]),_c('button',{staticClass:"rounded-full border border-primaryGray text-primaryGray p-1 w-5 h-5 text-xs flex justify-center items-center",on:{"click":function($event){return _vm.$emit('remove-item', index)}}},[_vm._v(" x ")])])]),_c('div',{staticClass:"mt-1 flex justify-between items-center"},[_c('button',{staticClass:"flex gap-2",on:{"click":function($event){return _vm.toggleDiscountModal(item, index)}}},[_c('span',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"},[_vm._v(" + ")]),_c('span',{staticClass:"text-lightBlue text-xs"},[_vm._v("Add discount")])]),_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                'opacity-25': item.manageStock && item.quantity <= 0,
              },attrs:{"disabled":!item.quantity || item.quantity <= 0},on:{"click":function($event){return _vm.updateQuantity({ value: -1, item })}}},[_vm._v(" - ")]),_c('span',{staticClass:"text-lightBlue text-xs"},[_vm._v(_vm._s(item.quantity || 0))]),_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                'opacity-25':
                  item.quantity >= item.stockQuantity && item.manageStock,
              },attrs:{"disabled":item.quantity >= item.stockQuantity && item.manageStock},on:{"click":function($event){return _vm.updateQuantity({ value: 1, item })}}},[_vm._v(" + ")])])])])}),0),_c('button',{staticClass:"text-lightBlue text-xs font-bold flex w-full justify-center items-center px-3 py-3 border-t border-t-lightGray-100",on:{"click":function($event){return _vm.$emit('clear')}}},[_vm._v(" CLEAR SALE ")])]):_vm._e(),_c('div',[_c('textarea',{staticClass:"border rounded-md border-lightGray-100 py-4 px-3 mb-4 w-full text-xs outline-none",attrs:{"rows":"3","placeholder":"Notes..."},domProps:{"value":_vm.item.description},on:{"input":function($event){return _vm.$emit('set-data', { description: $event.target.value })}}})]),_c('button',{staticClass:"bg-lightBlue w-full justify-center py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase",class:{
      'opacity-50': _vm.disableChargeButton,
    },attrs:{"disabled":_vm.disableChargeButton},on:{"click":_vm.charge}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" CHARGE "+_vm._s(_vm._f("formatPrice")(_vm.totalPrice)))])]),(_vm.showDiscountModal)?_c('trac-modal',{on:{"close":function($event){_vm.showDiscountModal = false}}},[_c('trac-custom-header',{staticClass:"mb-2"},[_c('template',{slot:"leading"},[_vm._v(_vm._s(_vm.selectedItem.title)+" ")])],2),_c('div',{staticClass:"flex justify-between mb-8"},[_c('trac-checkbox',{attrs:{"value":"amount","isChecked":_vm.selectedItem.discount_type === 'amount'},on:{"check":function($event){return _vm.formatDiscount({ discount_type: 'amount' })}}},[_c('p',{staticClass:"text-xs px-2 text-primaryBlue block whitespace-no-wrap"},[_vm._v(" Amount ")])]),_c('trac-checkbox',{attrs:{"value":"percentage","isChecked":_vm.selectedItem.discount_type === 'percentage'},on:{"check":function($event){return _vm.formatDiscount({ discount_type: 'percentage' })}}},[_c('p',{staticClass:"text-xs px-2 text-primaryBlue block whitespace-no-wrap"},[_vm._v(" Percentage ")])])],1),_c('div',{staticClass:"mb-5"},[_c('trac-input',{attrs:{"placeholder":"Enter discount"},model:{value:(_vm.selectedItem.discount),callback:function ($$v) {_vm.$set(_vm.selectedItem, "discount", _vm._n($$v))},expression:"selectedItem.discount"}}),_c('div',{staticClass:"flex justify-end"},[(_vm.discounts.find((d) => d.id === _vm.selectedItem.id))?_c('button',{staticClass:"text-xs text-secondaryRed",on:{"click":_vm.clearDiscount}},[_vm._v(" Clear discount ")]):_vm._e()])],1),_c('button',{staticClass:"bg-lightBlue w-full justify-center py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase",on:{"click":function($event){return _vm.updateDiscount(_vm.selectedItem)}}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" ADD DISCOUNT")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }