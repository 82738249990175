var render = function render(){var _vm=this,_c=_vm._self._c;return _c('trac-modal',{on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"mb-5"},[_c('h5',{staticClass:"text-xl font-medium"},[_vm._v("Product: "+_vm._s(_vm.item.title))]),_c('p',{staticClass:"text-xs"},[_vm._v("Select an Option Below")])]),(_vm.item.variations && _vm.item.variations.length)?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"grid grid-cols-12 gap-x-8 gap-y-1"},[_c('h4',{staticClass:"text-xs font-bold col-span-full"},[_vm._v("VARIATIONS")]),_vm._l((_vm.item.variations || []),function(variation,index){return _c('div',{key:variation.id,staticClass:"col-span-6 text-sm grid grid-cols-12 items-center"},[_c('div',{staticClass:"col-span-6"},[_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(variation.title))]),_c('span',{staticClass:"text-lightGray-300"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(variation.salePrice))+" ")])]),_c('div',{staticClass:"col-span-6"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                'opacity-25': !variation.quantity || variation.quantity <= 0,
              },attrs:{"disabled":!variation.quantity || variation.quantity <= 0},on:{"click":function($event){return _vm.updateQuantity({ index, value: -1, key: 'variations' })}}},[_vm._v(" - ")]),_c('span',{staticClass:"text-lightBlue"},[_vm._v(_vm._s(variation.quantity || 0))]),_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                'opacity-25':
                  variation.quantity >= variation.stockQuantity &&
                  variation.manageStock,
              },attrs:{"disabled":variation.quantity >= variation.stockQuantity &&
                variation.manageStock},on:{"click":function($event){return _vm.updateQuantity({ index, value: 1, key: 'variations' })}}},[_vm._v(" + ")])])])])})],2)]):_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"text-sm grid grid-cols-12 items-center"},[_c('div',{staticClass:"col-span-6 grid grid-cols-12 items-center"},[_c('div',{staticClass:"col-span-6"},[_c('h4',{staticClass:"text-xs font-bold"},[_vm._v("SIMPLE PRODUCT")]),_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.item.title))]),_c('span',{staticClass:"text-lightGray-300"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.item.salePrice))+" ")])]),_c('div',{staticClass:"col-span-6"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                'opacity-25': !_vm.item.quantity || _vm.item.quantity <= 0,
              },attrs:{"disabled":!_vm.item.quantity || _vm.item.quantity <= 0},on:{"click":function($event){return _vm.updateQuantity({ value: -1, key: '' })}}},[_vm._v(" - ")]),_c('span',{staticClass:"text-lightBlue"},[_vm._v(_vm._s(_vm.item.quantity || 0))]),_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                'opacity-25':
                  _vm.item.quantity >= _vm.getStockQuantity(_vm.item) && _vm.item.manageStock,
              },attrs:{"disabled":_vm.item.quantity >= _vm.getStockQuantity(_vm.item) && _vm.item.manageStock},on:{"click":function($event){return _vm.updateQuantity({ value: 1, key: '' })}}},[_vm._v(" + ")])])])])])]),(_vm.item.modifiers && _vm.item.modifiers.flatMap((m) => m).length)?_c('div',[_c('h6',{staticClass:"text-xs font-bold"},[_vm._v("MODIFIERS")]),_c('div',{staticClass:"flex flex-col gap-4"},_vm._l((_vm.item.modifiers.flatMap((m) => m)),function(modifier,id){return _c('div',{key:id},[(modifier.variations && modifier.variations.length)?_c('p',{staticClass:"text-lightGray-300 mb-2 uppercase text-xs"},[_vm._v(" "+_vm._s(modifier.title)+" ")]):_vm._e(),(modifier.variations && modifier.variations.length)?_c('div',{staticClass:"grid grid-cols-12 gap-8"},_vm._l((modifier.variations || []),function(variation,index){return _c('div',{key:variation.id,staticClass:"col-span-6 text-sm grid grid-cols-12 items-center"},[_c('div',{staticClass:"col-span-6"},[_c('p',{staticClass:"text-lg"},[_vm._v(_vm._s(variation.title))]),_c('span',{staticClass:"text-lightGray-300"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(variation.salePrice))+" ")])]),_c('div',{staticClass:"col-span-6"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                    'opacity-25':
                      !variation.quantity || variation.quantity <= 0,
                  },attrs:{"disabled":!variation.quantity || variation.quantity <= 0},on:{"click":function($event){return _vm.updateModifierQuantity({
                      id,
                      index,
                      value: -1,
                      key: 'variations',
                    })}}},[_vm._v(" - ")]),_c('span',{staticClass:"text-lightBlue"},[_vm._v(_vm._s(variation.quantity || 0))]),_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                    'opacity-25':
                      variation.quantity >= variation.stockQuantity,
                  },attrs:{"disabled":variation.quantity >= variation.stockQuantity},on:{"click":function($event){return _vm.updateModifierQuantity({
                      id,
                      index,
                      value: 1,
                      key: 'variations',
                    })}}},[_vm._v(" + ")])])])])}),0):[_c('div',{staticClass:"text-sm grid grid-cols-12 items-center"},[_c('div',{staticClass:"col-span-6 grid grid-cols-12 items-center"},[_c('div',{staticClass:"col-span-6"},[_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(modifier.title))]),_c('span',{staticClass:"text-lightGray-300"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(modifier.salePrice))+" ")])]),_c('div',{staticClass:"col-span-6"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                      'opacity-25':
                        !modifier.quantity || modifier.quantity <= 0,
                    },attrs:{"disabled":!modifier.quantity || modifier.quantity <= 0},on:{"click":function($event){return _vm.updateModifierQuantity({
                        id,
                        value: -1,
                        key: '',
                      })}}},[_vm._v(" - ")]),_c('span',{staticClass:"text-lightBlue"},[_vm._v(_vm._s(modifier.quantity || 0))]),_c('button',{staticClass:"active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm",class:{
                      'opacity-25':
                        modifier.quantity >= modifier.stockQuantity &&
                        modifier.manageStock,
                    },attrs:{"disabled":modifier.quantity >= modifier.stockQuantity &&
                      modifier.manageStock},on:{"click":function($event){return _vm.updateModifierQuantity({
                        id,
                        value: 1,
                        key: '',
                      })}}},[_vm._v(" + ")])])])])])]],2)}),0)]):_vm._e(),_c('div',{staticClass:"flex justify-end mt-3"},[_c('button',{staticClass:"bg-lightBlue py-2 font-medium flex items-center text-white rounded-md px-6 whitespace-no-wrap uppercase",on:{"click":_vm.addToOrder}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" Add to order ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }