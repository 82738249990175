<template>
  <trac-modal @close="$emit('close')">
    <div class="mb-5">
      <h5 class="text-xl font-medium">Product: {{ item.title }}</h5>
      <p class="text-xs">Select Option(s) Below</p>
    </div>

    <div class="flex flex-col gap-4 my-4">
      <div
        v-for="([key, values], index) in Object.entries(flattenedOptions)"
        :key="key"
      >
        <p class="text-xs mb-1">
          Select Option {{ index + 1 }}:
          <span class="capitalize">{{ key }}</span>
        </p>
        <div class="grid grid-cols-12 gap-8">
          <button
            v-for="(value, id) in values"
            :key="id"
            class="col-span-4 border rounded-md py-1 focus:outline-none text-sm flex justify-center items-center"
            :class="{
              'border-lightBlue text-lightBlue bg-lightBlue-100':
                isInOptionCombined(`${key}-${value}`),
              'opacity-50': index > valuesSelected.length,
            }"
            :disabled="index > valuesSelected.length"
            @click="toggleOptionValue(key, value, index)"
          >
            <div class="flex items-center gap-2">
              <div v-if="isInOptionCombined(`${key}-${value}`)">
                <img src="@/assets/svg/check-blue.svg" alt="check-blue" />
              </div>
              {{ value }}
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <div class="flex gap-4">
        <button
          class="py-2 text-xs font-medium flex items-center border rounded-md px-12 whitespace-no-wrap uppercase"
          @click="$emit('close')"
        >
          BACK
        </button>
        <button
          class="bg-lightBlue py-2 text-xs font-medium flex items-center text-white rounded-md px-12 whitespace-no-wrap uppercase"
          :class="{
            'opacity-50': !valuesSelected.length,
          }"
          :disabled="!valuesSelected.length"
          @click="filterValue"
        >
          PROCEED
        </button>
      </div>
    </div>

    <AddProductDefault
      v-if="!!selectedItem"
      :product="selectedItem"
      @add-to-order="
        (e) => {
          $emit('add-to-order', e);
        }
      "
      @close="selectedItem = null"
    />
  </trac-modal>
</template>

<script>
import AddProductDefault from "./AddProductDefault.vue";
import { eventBus } from "@/eventBus";

export default {
  name: "AddProductOptions",
  components: { AddProductDefault },
  emits: ["close", "add-to-order"],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      item: { ...this.product },
      valuesSelected: [],
      selectedItem: null,
    };
  },
  computed: {
    flattenedOptions() {
      return (this.item.variations ?? [])
        ?.flatMap((v) => v.options ?? [])
        ?.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.key]: [...new Set([...(acc[curr.key] ?? []), curr.value])],
          };
        }, {});
    },
  },
  methods: {
    isInOptionCombined(option) {
      return this.valuesSelected.includes(option);
    },
    toggleOptionValue(key, value, index) {
      if (this.valuesSelected.includes(`${key}-${value}`)) {
        this.valuesSelected = this.valuesSelected.filter(
          (val) => `${key}-${value}` !== val
        );
        return;
      }

      this.valuesSelected.splice(index, 1, `${key}-${value}`);
    },
    filterValue() {
      const variationFound = this.item.variations.find((v) => {
        const matched = v.options.every(({ key, value }) =>
          this.valuesSelected
            .map((v) => v.toLowerCase())
            .includes(`${key}-${value}`.toLowerCase())
        );
        return matched;
      });

      if (variationFound) {
        this.selectedItem = {
          ...this.item,
          variations: [variationFound],
        };
        return;
      }

      eventBus.$emit("trac-alert", {
        message: "No variations for the selected option(s)",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
