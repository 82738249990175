<template>
  <div>
    <div class="mb-5">
      <adv-dropdown
        :module="'customers'"
        :string-fields="['first_name', 'last_name']"
        :add-record-page="'AddCustomer'"
        @on-selection="selectCustomer"
        @add-record-path="gotoPage"
        class="p-1.5"
      />
    </div>

    <!-- Products with editable discount fields -->
    <div
      v-if="item.items.length"
      class="border border-lightGray-100 rounded-md mb-4"
    >
      <div
        class="px-6 py-8 flex flex-col gap-6 overflow-y-scroll"
        style="max-height: 300px"
      >
        <div v-for="(item, index) in itemsWithDiscount" :key="item.id" class="">
          <div class="flex justify-between">
            <div>
              <h6 class="text-sm">{{ item.title }}</h6>
              <span class="text-xs text-primaryGray">
                {{ item.quantity }} X {{ item.salePrice | formatPrice }} Each
              </span>
            </div>
            <div class="flex gap-2">
              <div>
                <h6 v-if="item.discount" class="text-sm font-bold">
                  {{ getDiscount(item) | formatPrice }}
                </h6>
                <h6
                  class="font-bold"
                  :class="{
                    'line-through text-primaryGray text-xs': item.discount,
                  }"
                >
                  {{ (item.salePrice * item.quantity) | formatPrice }}
                </h6>
              </div>
              <button
                class="rounded-full border border-primaryGray text-primaryGray p-1 w-5 h-5 text-xs flex justify-center items-center"
                @click="$emit('remove-item', index)"
              >
                x
              </button>
            </div>
          </div>
          <div class="mt-1 flex justify-between items-center">
            <button
              class="flex gap-2"
              @click="toggleDiscountModal(item, index)"
            >
              <span
                class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
              >
                +
              </span>
              <span class="text-lightBlue text-xs">Add discount</span>
            </button>
            <div class="flex items-center gap-4">
              <button
                class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                :class="{
                  'opacity-25': item.manageStock && item.quantity <= 0,
                }"
                :disabled="!item.quantity || item.quantity <= 0"
                @click="updateQuantity({ value: -1, item })"
              >
                -
              </button>
              <span class="text-lightBlue text-xs">{{
                item.quantity || 0
              }}</span>
              <button
                class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                :class="{
                  'opacity-25':
                    item.quantity >= item.stockQuantity && item.manageStock,
                }"
                :disabled="
                  item.quantity >= item.stockQuantity && item.manageStock
                "
                @click="updateQuantity({ value: 1, item })"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        class="text-lightBlue text-xs font-bold flex w-full justify-center items-center px-3 py-3 border-t border-t-lightGray-100"
        @click="$emit('clear')"
      >
        CLEAR SALE
      </button>
    </div>

    <!-- Notes -->
    <div>
      <textarea
        class="border rounded-md border-lightGray-100 py-4 px-3 mb-4 w-full text-xs outline-none"
        rows="3"
        :value="item.description"
        @input="$emit('set-data', { description: $event.target.value })"
        placeholder="Notes..."
      />
    </div>

    <!-- Charge -->
    <button
      class="bg-lightBlue w-full justify-center py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase"
      :class="{
        'opacity-50': disableChargeButton,
      }"
      :disabled="disableChargeButton"
      @click="charge"
    >
      <span style="font-size: 10px">
        CHARGE {{ totalPrice | formatPrice }}</span
      >
    </button>

    <!-- Discount Modal -->
    <trac-modal v-if="showDiscountModal" @close="showDiscountModal = false">
      <trac-custom-header class="mb-2">
        <template slot="leading">{{ selectedItem.title }} </template>
      </trac-custom-header>

      <div class="flex justify-between mb-8">
        <trac-checkbox
          value="amount"
          :isChecked="selectedItem.discount_type === 'amount'"
          @check="formatDiscount({ discount_type: 'amount' })"
        >
          <p class="text-xs px-2 text-primaryBlue block whitespace-no-wrap">
            Amount
          </p>
        </trac-checkbox>
        <trac-checkbox
          value="percentage"
          :isChecked="selectedItem.discount_type === 'percentage'"
          @check="formatDiscount({ discount_type: 'percentage' })"
        >
          <p class="text-xs px-2 text-primaryBlue block whitespace-no-wrap">
            Percentage
          </p>
        </trac-checkbox>
      </div>

      <div class="mb-5">
        <trac-input
          v-model.number="selectedItem.discount"
          placeholder="Enter discount"
        />
        <div class="flex justify-end">
          <button
            v-if="discounts.find((d) => d.id === selectedItem.id)"
            class="text-xs text-secondaryRed"
            @click="clearDiscount"
          >
            Clear discount
          </button>
        </div>
      </div>

      <button
        class="bg-lightBlue w-full justify-center py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase"
        @click="updateDiscount(selectedItem)"
      >
        <span style="font-size: 10px"> ADD DISCOUNT</span>
      </button>
    </trac-modal>
  </div>
</template>

<script>
import AdvDropdown from "@/offline-module/components/advDropdownFilter.vue";
import { mapActions, mapGetters } from "vuex";
import { SAVE_LOCAL_DB_DATA } from "../../../browser-db-config/localStorage";
import { eventBus } from "../../../eventBus";
import { getDiscountValue } from "../../../utils";

export default {
  name: "ProductDiscount",
  components: { AdvDropdown },
  emits: ["remove-item", "clear", "set-data"],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      products: [],
      discounts: [],
      showDiscountModal: false,
      selectedItem: {},
    };
  },
  created() {
    this.FETCH_ALL_CUSTOMERS();
    if (this.item.items?.length) {
      this.item.items.forEach((item) =>
        this.discounts.push({
          id: item.id,
          discount: item.discount || "",
          discount_type: item.discount_type || "",
        })
      );
    }
  },
  computed: {
    ...mapGetters({
      customers: "GET_ALL_CUSTOMERS",
    }),
    itemsWithDiscount() {
      return this.item.items.map((item) => {
        const discountData = this.discounts.find(
          (discount) => discount.id === item.id
        );
        return {
          ...item,
          discount: discountData?.discount || 0,
          discount_type: discountData?.discount_type,
        };
      });
    },
    disableChargeButton() {
      return !this.item.items?.length;
    },
    totalPrice() {
      return this.itemsWithDiscount.reduce((acc, item) => {
        const totalPrice = item.salePrice * (item.quantity || 1);
        const discountedPrice = getDiscountValue(item);
        const newPrice = totalPrice - discountedPrice;
        return acc + newPrice;
      }, 0);
    },
  },
  methods: {
    ...mapActions({ FETCH_ALL_CUSTOMERS: "FETCH_ALL_CUSTOMERS" }),
    toggleDiscountModal(item) {
      this.showDiscountModal = !this.showDiscountModal;
      const discount = this.discounts.find((d) => d.id === item.id);
      this.selectedItem = {
        ...item,
        discount_type: discount?.discount_type ?? "amount",
      };
    },
    selectCustomer(option) {
      this.$emit("set-data", { customer_info: option });
    },
    gotoPage(path) {
      this.$router.push({ name: path });
    },
    getDiscount(item, useDef = false) {
      const discount = useDef
        ? item
        : this.discounts.find((d) => d.id === item.id);
      if (discount) {
        if (discount.discount_type === "amount") {
          return item.salePrice * item.quantity - discount.discount;
        }
        if (discount.discount_type === "percentage") {
          return (
            item.salePrice * item.quantity -
            (discount.discount / 100) * (item.salePrice * item.quantity)
          );
        }
      }
      return 0;
    },
    formatDiscount(data) {
      this.selectedItem = {
        ...this.selectedItem,
        ...data,
      };
    },
    updateDiscount(item) {
      const discountData = this.discounts.find((d) => d.id === item.id);
      const { id, discount, discount_type } = item;

      if (this.getDiscount(item, true) <= 0) {
        return eventBus.$emit("trac-alert", {
          message: "The price after discount cannot be lower than 1",
        });
      }

      this.showDiscountModal = false;

      if (discountData) {
        Object.assign(discountData, {
          ...discountData,
          id,
          discount,
          discount_type,
        });
        return;
      }

      this.discounts.push({
        id,
        discount,
        discount_type,
      });
    },
    updateQuantity({ value, item }) {
      const newItems = this.itemsWithDiscount.map((itemData) => {
        if (itemData.id === item.id) {
          return {
            ...itemData,
            quantity: itemData.quantity + value,
          };
        }
        return itemData;
      });

      this.$emit("set-data", { items: newItems });
    },
    clearDiscount() {
      const discountData = this.discounts.find(
        (d) => d.id === this.selectedItem.id
      );
      discountData.discount = 0;
      this.showDiscountModal = false;
    },
    charge() {
      SAVE_LOCAL_DB_DATA("pos-sales-data", {
        ...this.item,
        items: this.itemsWithDiscount,
      });
      this.$router.push({ name: "PosPayment" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
