<template>
  <div>
    <trac-loading v-if="loading || loadingProducts" />
    <div class="flex justify-end mb-6">
      <div class="w-2/5">
        <trac-dropdown-exteneded
          :neededProperty="'name'"
          placement="left"
          :selector="'All Categories'"
          :options="[{ name: 'All', id: undefined }, ...(allCategories || [])]"
          @optionSelected="setCategory($event)"
          class="mb-2 md:mb-0"
        />
      </div>
    </div>

    <div class="mb-10">
      <input
        v-model="extraParams.keyword"
        type="text"
        class="px-3 border w-full rounded-md border-lightBlur py-2 outline-none text-sm"
        placeholder="Search from all"
      />
    </div>

    <div v-if="!products.length && (!loading || !loadingProducts)">
      <p class="font-medium text-sm">
        No products to show
        <span v-if="selectedCategory.name">
          for <span class="capitalize">{{ selectedCategory.name }}</span>
        </span>
      </p>
    </div>
    <div v-else>
      <div class="grid grid-cols-12 gap-8">
        <div
          v-for="product in filteredProducts"
          :key="product.id"
          class="col-span-3 rounded-md border border-lightGray-100 overflow-hidden cursor-pointer"
          @click="selectProduct(product)"
        >
          <div
            class="w-full border-b border-b-lightGray-100"
            style="height: 100px"
          >
            <div v-if="!product.image" class="bg-lightGray-200 h-full" />
            <div v-else class="h-full flex justify-center items-center">
              <img
                :src="product.image"
                :alt="product.title"
                class="object-cover h-full"
              />
            </div>
          </div>
          <p
            class="flex justify-center items-center py-2 font-medium"
            style="font-size: 9px"
          >
            {{ product.title }}
          </p>
        </div>
      </div>
    </div>

    <!-- <div v-if="totalCount > products.length" class="flex justify-center mt-4">
      <trac-button
        :disabled="products.length >= totalCount"
        @button-clicked="loadMoreProducts"
      >
        Load More
      </trac-button>
    </div> -->

    <AddProductDefault
      v-if="showAddProductType === 'default'"
      :product="selectedProduct"
      @add-to-order="
        (e) => {
          $emit('add-to-order', e);
          showAddProductType = '';
        }
      "
      @close="showAddProductType = ''"
    />
    <AddProductOptions
      v-if="showAddProductType === 'options'"
      :product="selectedProduct"
      @add-to-order="
        (e) => {
          $emit('add-to-order', e);
          showAddProductType = '';
        }
      "
      @close="showAddProductType = ''"
    />
  </div>
</template>

<script>
import { eventBus } from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import AddProductDefault from './AddProductDefault.vue';
import AddProductOptions from './AddProductOptions.vue';

const initialParams = {
  limit: 20,
  page: 1,
};

export default {
  components: { AddProductDefault, AddProductOptions },
  name: 'POSProducts',
  emits: ['add-to-order'],
  props: {
    store: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      loadingProducts: false,
      selectedCategory: {},
      searchValue: '',
      extraParams: {
        ...initialParams,
      },
      products: [],
      timeout: null,
      totalCount: 0,
      showAddProductType: '',
      selectedProduct: {},
    };
  },
  created() {
    Promise.all([this.fetchCategories(), this.fetchProducts()]);
  },
  computed: {
    ...mapGetters({
      allCategories: 'GET_PRODUCT_CATEGORIES',
    }),
    params() {
      return {
        storeID: this.store?.id,
        productType: 'simple',
        ...this.extraParams,
      };
    },
    filteredProducts() {
      return this.products
        ?.filter((prod) =>
          prod.title?.toLowerCase().match(this.extraParams.keyword)
        )
        ?.filter((prod) =>
          prod.categories?.id.match(this.selectedCategory?.id)
        );
    },
  },
  methods: {
    ...mapActions(['FETCH_PRODUCTS_CATEGORIES', 'FETCH_PRODUCTS_SYNC']),
    async fetchProducts() {
      this.loadingProducts = true;
      try {
        const res = await this.FETCH_PRODUCTS_SYNC();
        this.products =
          res.data.products?.filter(
            (product) =>
              !['modifier-with-variant', 'modifier'].includes(product.type)
          ) || [];
        this.totalCount = res.data.products?.length;
      } catch (error) {
        console.log(error);
        return eventBus.$emit('trac-alert', {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loadingProducts = false;
      }
    },
    async fetchCategories() {
      this.loading = true;
      try {
        await this.FETCH_PRODUCTS_CATEGORIES();
      } catch (error) {
        console.log(error);
        return eventBus.$emit('trac-alert', {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    setCategory(category) {
      // console.log(category);
      this.selectedCategory = category;
      // this.extraParams = {
      //   ...this.extraParams,
      //   category: category.id,
      //   page: 1,
      //   limit: initialParams.limit,
      // };
      // this.fetchProducts();
    },
    loadMoreProducts() {
      this.extraParams = {
        ...this.extraParams,
        limit: this.extraParams.limit + 10,
      };

      this.fetchProducts();
    },
    selectProduct(product) {
      const dt = (this.items || []).filter(
        (item) =>
          product?.id === item?.id ||
          !!product?.variations?.find((v) => v?.id === item?.id)
      );
      this.selectedProduct = { ...product };

      if (product?.variations?.length) {
        this.selectedProduct.variations = product.variations.map(
          (variation) => ({
            ...variation,
            quantity: dt.find((d) => variation?.id === d?.id)?.quantity,
          })
        );
      } else {
        this.selectedProduct.quantity =
          dt?.[0]?.quantity || (dt?.[0]?.stockQuantity ? 1 : 0);
      }

      if (product?.modifiers?.length) {
        const mt = (this.items || []).filter(
          (item) =>
            !!product.modifiers.find(
              (modifier) =>
                modifier.variations?.find((v) => v?.id === item?.id) ||
                modifier.id === item.id
            )
        );

        this.selectedProduct.modifiers = product.modifiers.map((modifier) => {
          if (modifier?.variations?.length) {
            return modifier.variations.map((variation) => ({
              ...variation,
              quantity: mt.find((m) => variation?.id === m?.id)?.quantity || 0,
            }));
          } else {
            return {
              ...modifier,
              quantity: mt?.[0]?.quantity || 1,
            };
          }
        });
      }

      if (
        product.checkoutOption?.name === 'Default' ||
        !product.checkoutOption?.name
      ) {
        if (
          !this.selectedProduct?.modifiers.length &&
          !this.selectedProduct?.variations?.length
        ) {
          this.$emit('add-to-order', [
            { ...this.selectedProduct, quantity: 1 },
          ]);
          return;
        }
        this.showAddProductType = 'default';
      } else {
        if (product.hasVariant) {
          this.showAddProductType = 'options';
          return;
        }
        this.showAddProductType = 'default';
      }
    },
  },
  // watch: {
  //   "extraParams.keyword": {
  //     handler() {
  //       clearTimeout(this.timeout);

  //       this.timeout = setTimeout(() => {
  //         this.extraParams = {
  //           ...this.extraParams,
  //           page: 1,
  //           limit: initialParams.limit,
  //         };
  //         this.fetchProducts();
  //       }, 100);
  //     },
  //   },
  // },
};
</script>

<style lang="scss" scoped></style>
