var render = function render(){var _vm=this,_c=_vm._self._c;return _c('trac-modal',{on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"mb-5"},[_c('h5',{staticClass:"text-xl font-medium"},[_vm._v("Product: "+_vm._s(_vm.item.title))]),_c('p',{staticClass:"text-xs"},[_vm._v("Select Option(s) Below")])]),_c('div',{staticClass:"flex flex-col gap-4 my-4"},_vm._l((Object.entries(_vm.flattenedOptions)),function([key, values],index){return _c('div',{key:key},[_c('p',{staticClass:"text-xs mb-1"},[_vm._v(" Select Option "+_vm._s(index + 1)+": "),_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(key))])]),_c('div',{staticClass:"grid grid-cols-12 gap-8"},_vm._l((values),function(value,id){return _c('button',{key:id,staticClass:"col-span-4 border rounded-md py-1 focus:outline-none text-sm flex justify-center items-center",class:{
            'border-lightBlue text-lightBlue bg-lightBlue-100':
              _vm.isInOptionCombined(`${key}-${value}`),
            'opacity-50': index > _vm.valuesSelected.length,
          },attrs:{"disabled":index > _vm.valuesSelected.length},on:{"click":function($event){return _vm.toggleOptionValue(key, value, index)}}},[_c('div',{staticClass:"flex items-center gap-2"},[(_vm.isInOptionCombined(`${key}-${value}`))?_c('div',[_c('img',{attrs:{"src":require("@/assets/svg/check-blue.svg"),"alt":"check-blue"}})]):_vm._e(),_vm._v(" "+_vm._s(value)+" ")])])}),0)])}),0),_c('div',{staticClass:"flex justify-end"},[_c('div',{staticClass:"flex gap-4"},[_c('button',{staticClass:"py-2 text-xs font-medium flex items-center border rounded-md px-12 whitespace-no-wrap uppercase",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" BACK ")]),_c('button',{staticClass:"bg-lightBlue py-2 text-xs font-medium flex items-center text-white rounded-md px-12 whitespace-no-wrap uppercase",class:{
          'opacity-50': !_vm.valuesSelected.length,
        },attrs:{"disabled":!_vm.valuesSelected.length},on:{"click":_vm.filterValue}},[_vm._v(" PROCEED ")])])]),(!!_vm.selectedItem)?_c('AddProductDefault',{attrs:{"product":_vm.selectedItem},on:{"add-to-order":(e) => {
        _vm.$emit('add-to-order', e);
      },"close":function($event){_vm.selectedItem = null}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }