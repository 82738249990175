<template>
  <trac-modal @close="$emit('close')">
    <div class="mb-5">
      <h5 class="text-xl font-medium">Product: {{ item.title }}</h5>
      <p class="text-xs">Select an Option Below</p>
    </div>

    <!-- Products list -->
    <div v-if="item.variations && item.variations.length" class="mb-4">
      <div class="grid grid-cols-12 gap-x-8 gap-y-1">
        <h4 class="text-xs font-bold col-span-full">VARIATIONS</h4>
        <div
          v-for="(variation, index) in item.variations || []"
          :key="variation.id"
          class="col-span-6 text-sm grid grid-cols-12 items-center"
        >
          <div class="col-span-6">
            <p class="text-xs">{{ variation.title }}</p>
            <span class="text-lightGray-300">
              {{ variation.salePrice | formatPrice }}
            </span>
          </div>
          <div class="col-span-6">
            <div class="flex items-center gap-4">
              <button
                class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                :class="{
                  'opacity-25': !variation.quantity || variation.quantity <= 0,
                }"
                :disabled="!variation.quantity || variation.quantity <= 0"
                @click="updateQuantity({ index, value: -1, key: 'variations' })"
              >
                -
              </button>
              <span class="text-lightBlue">{{ variation.quantity || 0 }}</span>
              <button
                class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                :class="{
                  'opacity-25':
                    variation.quantity >= variation.stockQuantity &&
                    variation.manageStock,
                }"
                :disabled="
                  variation.quantity >= variation.stockQuantity &&
                  variation.manageStock
                "
                @click="updateQuantity({ index, value: 1, key: 'variations' })"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mb-4">
      <div class="text-sm grid grid-cols-12 items-center">
        <div class="col-span-6 grid grid-cols-12 items-center">
          <div class="col-span-6">
            <h4 class="text-xs font-bold">SIMPLE PRODUCT</h4>
            <p class="text-xs">{{ item.title }}</p>
            <span class="text-lightGray-300">
              {{ item.salePrice | formatPrice }}
            </span>
          </div>
          <div class="col-span-6">
            <div class="flex items-center gap-4">
              <button
                class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                :class="{
                  'opacity-25': !item.quantity || item.quantity <= 0,
                }"
                :disabled="!item.quantity || item.quantity <= 0"
                @click="updateQuantity({ value: -1, key: '' })"
              >
                -
              </button>
              <span class="text-lightBlue">{{ item.quantity || 0 }}</span>
              <button
                class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                :class="{
                  'opacity-25':
                    item.quantity >= getStockQuantity(item) && item.manageStock,
                }"
                :disabled="
                  item.quantity >= getStockQuantity(item) && item.manageStock
                "
                @click="updateQuantity({ value: 1, key: '' })"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modifiers list -->
    <div v-if="item.modifiers && item.modifiers.flatMap((m) => m).length">
      <h6 class="text-xs font-bold">MODIFIERS</h6>
      <div class="flex flex-col gap-4">
        <div
          v-for="(modifier, id) in item.modifiers.flatMap((m) => m)"
          :key="id"
        >
          <p
            v-if="modifier.variations && modifier.variations.length"
            class="text-lightGray-300 mb-2 uppercase text-xs"
          >
            {{ modifier.title }}
          </p>

          <div
            v-if="modifier.variations && modifier.variations.length"
            class="grid grid-cols-12 gap-8"
          >
            <div
              v-for="(variation, index) in modifier.variations || []"
              :key="variation.id"
              class="col-span-6 text-sm grid grid-cols-12 items-center"
            >
              <div class="col-span-6">
                <p class="text-lg">{{ variation.title }}</p>
                <span class="text-lightGray-300">
                  {{ variation.salePrice | formatPrice }}
                </span>
              </div>
              <div class="col-span-6">
                <div class="flex items-center gap-4">
                  <button
                    class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                    :class="{
                      'opacity-25':
                        !variation.quantity || variation.quantity <= 0,
                    }"
                    :disabled="!variation.quantity || variation.quantity <= 0"
                    @click="
                      updateModifierQuantity({
                        id,
                        index,
                        value: -1,
                        key: 'variations',
                      })
                    "
                  >
                    -
                  </button>
                  <span class="text-lightBlue">{{
                    variation.quantity || 0
                  }}</span>
                  <button
                    class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                    :class="{
                      'opacity-25':
                        variation.quantity >= variation.stockQuantity,
                    }"
                    :disabled="variation.quantity >= variation.stockQuantity"
                    @click="
                      updateModifierQuantity({
                        id,
                        index,
                        value: 1,
                        key: 'variations',
                      })
                    "
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="text-sm grid grid-cols-12 items-center">
              <div class="col-span-6 grid grid-cols-12 items-center">
                <div class="col-span-6">
                  <p class="text-xs">{{ modifier.title }}</p>
                  <span class="text-lightGray-300">
                    {{ modifier.salePrice | formatPrice }}
                  </span>
                </div>
                <div class="col-span-6">
                  <div class="flex items-center gap-4">
                    <button
                      class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                      :class="{
                        'opacity-25':
                          !modifier.quantity || modifier.quantity <= 0,
                      }"
                      :disabled="!modifier.quantity || modifier.quantity <= 0"
                      @click="
                        updateModifierQuantity({
                          id,
                          value: -1,
                          key: '',
                        })
                      "
                    >
                      -
                    </button>
                    <span class="text-lightBlue">{{
                      modifier.quantity || 0
                    }}</span>
                    <button
                      class="active:outline-none focus:outline-none text-lightBlue border-lightBlue border w-4 h-4 flex items-center justify-center rounded-full p-1 text-sm"
                      :class="{
                        'opacity-25':
                          modifier.quantity >= modifier.stockQuantity &&
                          modifier.manageStock,
                      }"
                      :disabled="
                        modifier.quantity >= modifier.stockQuantity &&
                        modifier.manageStock
                      "
                      @click="
                        updateModifierQuantity({
                          id,
                          value: 1,
                          key: '',
                        })
                      "
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-3">
      <button
        class="bg-lightBlue py-2 font-medium flex items-center text-white rounded-md px-6 whitespace-no-wrap uppercase"
        @click="addToOrder"
      >
        <span style="font-size: 10px"> Add to order </span>
      </button>
    </div>
  </trac-modal>
</template>

<script>
import { eventBus } from "../../../eventBus";

export default {
  name: "AddProductDefault",
  emits: ["close", "add-to-order"],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      item: { ...this.product },
    };
  },
  created() {
    console.log(this.item);
  },
  methods: {
    getStockQuantity(item) {
      return (
        item.stockQuantity ||
        (item.storeStock?.reduce((acc, s) => acc + s.stockQuantity, 0) ?? 0)
      );
    },
    updateQuantity({ index, value, key }) {
      if (!key) {
        // Updating a product
        this.item = {
          ...this.item,
          quantity: (this.item.quantity || 0) + value,
        };
        return;
      }
      // Updating a variant or modifier
      const updatedData = {
        ...this.item[key][index],
        quantity: (this.item[key][index]?.quantity ?? 0) + value,
      };
      this.item[key].splice(index, 1, updatedData);
    },
    updateModifierQuantity({ id, index, value, key }) {
      console.log(id, index);
      if (!key) {
        // Updating a product
        const modifier = this.item.modifiers?.flatMap((m) => m)?.[id];
        modifier.quantity = (modifier.quantity || 0) + value;
        this.item.modifiers?.flatMap((m) => m).splice(id, 1, modifier);
        // this.item = {
        //   ...this.item,
        //   modifiers: newModifiers,
        // };
        return;
      }
      // Updating a variant or modifier
      const updatedModifierVariant = {
        ...this.item.modifiers?.flatMap((m) => m)?.[id][key][index],
        quantity:
          (this.item.modifiers?.flatMap((m) => m)?.[id][key][index]?.quantity ??
            0) + value,
      };
      this.item.modifiers
        ?.flatMap((m) => m)
        ?.[id][key][index].splice(index, 1, updatedModifierVariant);
    },
    addToOrder() {
      const filteredVariationsItems = this.item?.variations?.length
        ? this.item?.variations.reduce((acc, variation) => {
            if (variation.quantity) {
              return [...acc, { ...variation, is_variant: true }];
            }
            return acc;
          }, [])
        : [{ ...this.item, is_variant: false }].filter((d) => !!d.quantity);

      const filteredModifiersItems = this.item?.modifiers?.flatMap((m) => m)
        ?.length
        ? this.item?.modifiers
            ?.flatMap((m) => m)
            ?.reduce((acc1, modifier) => {
              if (modifier?.variations?.length) {
                modifier?.variations.reduce((acc2, variation) => {
                  if (variation.quantity) {
                    return [...acc2, { ...variation, is_variant: true }];
                  }
                  return acc2;
                }, []);
              }
              return [...acc1, { ...modifier, is_variant: false }].filter(
                (d) => !!d.quantity
              );
            }, [])
        : [];

      const items = [...filteredVariationsItems, ...filteredModifiersItems];

      if (!items.length) {
        return eventBus.$emit("trac-alert", {
          message: "Add a quantity to at least one product to continue",
        });
      }

      this.$emit("add-to-order", items);
    },
  },
};
</script>

<style lang="scss" scoped></style>
