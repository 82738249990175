<template>
  <div>
    <trac-loading v-if="loading" />
    <button
      class="my-5 inline-flex cursor-pointer items-center text-xs"
      @click="goBack"
    >
      <svg
        class="mr-2 w-3 mb-px h-3"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00195 1.00147L1.00195 7.00147L7.00195 13.0015"
          stroke="#013E9E"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      Back
    </button>

    <div class="flex flex-col gap-y-4 mb-8">
      <h2>POS</h2>
      <div class="grid grid-cols-12 gap-8">
        <h3 class="font-bold text-2xl uppercase col-span-8">POS</h3>
        <div class="col-span-4" :class="showMinimizedCart ? 'hidden' : ''">
          <POSActions
            :item="formData"
            @charge-amount="addToQuickSale"
            @save-ticket="saveTicket"
          />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-8">
      <div class="col-span-8 bg-white rounded-lg big-shadow p-8 self-start">
        <POSProducts
          :items="formData.items"
          :store="store"
          @add-to-order="addProductsToOrder"
        />
      </div>
      <div class="col-span-4 bg-white rounded-lg big-shadow p-5">
        <ProductDiscount
          :item="formData"
          @remove-item="formData.items.splice($event, 1)"
          @set-data="
            formData = {
              ...formData,
              ...$event,
            }
          "
          @clear="formData.items = []"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
import POSProducts from "./POSProducts.vue";
import POSActions from "./POSActions.vue";
import ProductDiscount from "./ProductDiscount.vue";
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  components: {
    POSProducts,
    ProductDiscount,
    POSActions,
  },
  name: "POSDashboard",
  data() {
    return {
      store: {},
      loading: false,
      showMinimizedCart: false,
      formData: {
        items: [],
      },
    };
  },
  created() {
    this.store = GET_LOCAL_DB_DATA("pos-sales-store");
    if (GET_LOCAL_DB_DATA("pos-sales-data")) {
      this.formData = GET_LOCAL_DB_DATA("pos-sales-data");
      SAVE_LOCAL_DB_DATA("pos-sales-data", null);
    }
  },
  computed: {
    total() {
      return this.formData.items.reduce((acc, item) => {
        const totalPrice = item.salePrice * (item.quantity || 1);
        const discountedPrice = ((item.discount || 0) / 100) * item.salePrice;
        const newPrice = totalPrice - discountedPrice;
        return acc + newPrice;
      }, 0);
    },
    subTotal() {
      return this.formData.items.reduce((acc, item) => {
        const totalPrice = item.salePrice * (item.quantity || 1);
        return acc + totalPrice;
      }, 0);
    },
  },
  methods: {
    ...mapActions(["MAKE_SALE_V2"]),
    goBack() {
      SAVE_LOCAL_DB_DATA("pos-sales-store", "");
      this.$router.push({
        name: "Pos",
      });
    },
    addProductsToOrder(items) {
      let additionalItems = [...items];
      const newItems = this.formData.items.map((item) => {
        const itemAddedBefore = items.find((i) => i.id === item.id);
        if (itemAddedBefore) {
          additionalItems = additionalItems.filter((i) => i.id !== item.id);
          return {
            ...item,
            quantity: itemAddedBefore.quantity,
          };
        }
        return {
          ...item,
        };
      });

      this.formData.items = [...newItems, ...additionalItems]?.map((item) => ({
        ...item,
        is_stock_managed: !!item.manageStock,
      }));
      eventBus.$emit("trac-alert", {
        message: "Product(s) added",
      });
    },
    addToQuickSale(item) {
      this.formData.items.push({
        id: item._id,
        title: item.title,
        item_note: "",
        quantity: item.count,
        salePrice: item.quickSaleTotal,
        costPrice: item.quickSaleTotal,
        discount: 0,
        discount_type: "none",
        is_quick_sale: true,
        is_variant: false,
        is_stock_managed: false,
      });
    },
    async makePayment({ ticketName, onSuccess, onError } = {}) {
      const payload = {
        ...this.formData,
        items: this.formData.items?.map((item) => ({
          id: item.id,
          name: item.title,
          item_note: "",
          quantity: item.quantity,
          price: item.salePrice * item.quantity,
          unit_price: item.salePrice,
          discount: item.discount,
          discount_type: "none",
          is_quick_sale: false,
          is_variant: item.is_variant,
        })),
        total_price: this.total,
        sub_total: this.subTotal,
        amount_tendered: this.total,
        store_id: this.store.id,
        store_name: this.store.name,
        business_id: GET_USER_BUSINESS_ID(),
        // payments: [
        //   {
        //     amount_paid:
        //       this.bankInfo.payment_method !== "fixed_account"
        //         ? this.cashToCollect
        //           ? Number(this.cashToCollect)
        //           : this.total
        //         : this.total,
        //     payment_method: this.paymentMethod,
        //     payment_reference: this.paymentReferenceDetails?.payment_reference,
        //     payment_channel: this.paymentReferenceDetails?.payment_channel,
        //   },
        // ],
        sale_mode: "online",
        ticket_status: ticketName ? 0 : 1,
        ticket_name: ticketName,
      };

      this.loading = true;
      try {
        const res = await this.MAKE_SALE_V2(payload);
        if (res.status) {
          SAVE_LOCAL_DB_DATA("pos-sales-data", "");
          eventBus.$emit("trac-alert", {
            message: "Ticket sale created successfully",
          });
          onSuccess?.();
          this.$route.name !== "Pos" && this.$router.push({ name: "Pos" });
        } else {
          onError?.();
        }
      } catch (error) {
        onError?.(error);
        return eventBus.$emit("trac-alert", {
          message: error?.response?.data?.error,
        });
      } finally {
        this.loading = false;
      }
    },
    async saveTicket(data) {
      this.makePayment(data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
